import { cva, type VariantProps } from "./style.utils";

export type CardRootVariants = VariantProps<typeof cardRootVariants>;

export const cardRootVariants = cva({
  base: [
    "flex flex-col rounded-lg md:rounded-xl",
    "group/card @container/card",
  ],
  variants: {
    variant: {
      "white-shadow": "shadow",
      "white-shadow-md": "shadow-md",
      "white-border": "",
      "white-border-and-blur": "bg-white/80 backdrop-blur-xl",
      "blue-gradient":
        "from-19% to-86% bg-gradient-to-bl from-card-gradient-start to-card-gradient-end text-white",
    },
  },
  compoundVariants: [
    {
      variant: ["white-shadow", "white-shadow-md", "white-border"],
      className: "bg-white",
    },
    {
      variant: ["white-border", "white-border-and-blur"],
      className: "border border-shade-15",
    },
  ],
  defaultVariants: {
    variant: "white-shadow-md",
  },
});

// -----------------------------------------------------------------------------

export type CardHeaderVariants = VariantProps<typeof cardHeaderVariants>;

export const cardHeaderVariants = cva({
  base: [
    // layout
    "px-30px pt-30px",
    // radii/shadow
    "rounded-t-lg md:rounded-t-xl",
  ],
  variants: {
    variant: {
      unset: null,
      "accent-orange": [
        // border
        "border-t-chart-orange-100",
        // children foreground
        "[&_svg]:text-chart-orange-100",
      ],
      "accent-green": [
        // border
        "border-t-chart-green-100",
        // children foreground
        "[&_svg]:text-chart-green-100",
      ],
      "accent-purple": [
        // border
        "border-t-chart-purple-100",
        // children foreground
        "[&_svg]:text-chart-purple-100",
      ],
    },
    palette: {
      unset: null,
      white: "",
      gray: [
        // background
        "bg-shade-2",
        // override
        "group-[[data-variant=blue-gradient]]/card:bg-white/5",
        "group-[[data-variant=blue-gradient]]/card:text-white",
      ],
    },
  },
  compoundVariants: [
    {
      variant: ["accent-orange", "accent-green", "accent-purple"],
      className: [
        // border
        "border-t-[5px]",
        // override
        "rounded-t-[5px] md:rounded-t-[5px]",
      ],
    },
    {
      palette: ["white", "gray"],
      className: [
        // layout
        "pb-30px",
        // border
        "border-b border-shade-15",
        // override
        "group-[[data-variant=blue-gradient]]/card:border-white/10",
      ],
    },
  ],
  defaultVariants: {
    variant: "unset",
    palette: "unset",
  },
});

// -----------------------------------------------------------------------------

export type CardMediaVariants = VariantProps<typeof cardMediaVariants>;

export const cardMediaVariants = cva({
  base: [
    // layout
    "grid place-content-center p-30px",
    // radii/shadows
    "overflow-hidden",
  ],
  variants: {
    palette: {
      unset: null,
      primary: "bg-carousel-gradient-start",
      secondary: "bg-secondary-12",
    },
    pattern: {
      unset: null,
      splash: [
        "first:*:bg-current",
        "first:*:[mask-image:url('/Images/pattern-baird-splash.svg')]",
        "first:*:[mask-position:bottom]",
        "first:*:[mask-repeat:repeat-x]",
        "first:*:[mask-size:99px]",
      ],
      stripe: [
        "first:*:bg-[repeating-linear-gradient(-21.7deg,_currentColor,_currentColor_0.5px,_transparent_0.5px,_transparent_9px)]",
        "first:*:[mask-image:linear-gradient(-21.7deg,_#000000,_#000000_50%,_transparent_50%,_transparent)]",
      ],
    },
  },
  compoundVariants: [
    {
      pattern: ["splash", "stripe"],
      className: [
        // layout stacked (pattern under children)
        "relative isolate",
        // layout pattern
        "first:*:absolute first:*:inset-0",
        // layout children
        "last:*:z-10", // place over mask-image
      ],
    },
    {
      palette: "primary",
      pattern: "splash",
      className: "text-link-hover/15",
    },
    {
      palette: "secondary",
      pattern: "splash",
      className: "text-secondary-100/15",
    },
    {
      palette: "primary",
      pattern: "stripe",
      className: "text-primary-40",
    },
    {
      palette: "secondary",
      pattern: "stripe",
      className: "text-secondary-100",
    },
  ],
  defaultVariants: {
    palette: "unset",
    pattern: "unset",
  },
});

// -----------------------------------------------------------------------------

export type CardContentVariants = VariantProps<typeof cardContentVariants>;

export const cardContentVariants = cva({ base: "grow p-30px" });

// -----------------------------------------------------------------------------

export type CardFooterVariants = VariantProps<typeof cardFooterVariants>;

export const cardFooterVariants = cva({
  base: [
    // layout
    "flex flex-col gap-10px px-30px pb-30px",
    "@lg/card:flex-row @lg/card:items-center @lg/card:justify-end",
    // radii/shadow
    "rounded-b-lg md:rounded-b-xl",
  ],
  variants: {
    palette: {
      unset: null,
      white: "",
      gray: [
        // background
        "bg-shade-2",
        // override
        "group-[[data-variant=blue-gradient]]/card:bg-white/5",
        "group-[[data-variant=blue-gradient]]/card:text-white",
      ],
    },
  },
  compoundVariants: [
    {
      palette: ["white", "gray"],
      className: [
        // layout
        "py-15px",
        // border
        "border-t border-shade-15",
        // override
        "group-[[data-variant=blue-gradient]]/card:border-white/10",
      ],
    },
  ],
  defaultVariants: {
    palette: "unset",
  },
});
