import { forwardRef } from "react";
import {
  cardContentVariants,
  cardFooterVariants,
  cardHeaderVariants,
  cardMediaVariants,
  cardRootVariants,
  type CardContentVariants,
  type CardFooterVariants,
  type CardHeaderVariants,
  type CardMediaVariants,
  type CardRootVariants,
} from "./card.styles";

// -----------------------------------------------------------------------------

export type CardRootRef = React.ElementRef<"div">;

export interface CardRootProps
  extends React.ComponentPropsWithoutRef<"div">,
    CardRootVariants {}

const CardRoot = forwardRef<CardRootRef, CardRootProps>(
  ({ className, variant, ...props }, ref) => {
    return (
      <div
        {...props}
        className={cardRootVariants({ className, variant })}
        data-variant={variant}
        ref={ref}
      />
    );
  },
);

CardRoot.displayName = "CardRoot";

export { CardRoot as Card };

// -----------------------------------------------------------------------------

export type CardHeaderRef = React.ElementRef<"div">;

export interface CardHeaderProps
  extends React.ComponentPropsWithoutRef<"div">,
    CardHeaderVariants {}

const CardHeader = forwardRef<CardHeaderRef, CardHeaderProps>(
  ({ className, palette, variant, ...props }, ref) => {
    return (
      <div
        {...props}
        className={cardHeaderVariants({ className, palette, variant })}
        ref={ref}
      />
    );
  },
);

CardHeader.displayName = "CardHeader";

export { CardHeader };

// -----------------------------------------------------------------------------

export type CardMediaRef = React.ElementRef<"div">;

export interface CardMediaProps
  extends React.ComponentPropsWithoutRef<"div">,
    CardMediaVariants {}

const CardMedia = forwardRef<CardMediaRef, CardMediaProps>(
  ({ children, className, palette, pattern, ...props }, ref) => {
    return (
      <div
        {...props}
        className={cardMediaVariants({ className, palette, pattern })}
        ref={ref}
      >
        {pattern != null || pattern !== "unset" ? (
          <>
            <div />
            <div>{children}</div>
          </>
        ) : (
          children
        )}
      </div>
    );
  },
);

CardMedia.displayName = "CardMedia";

export { CardMedia };

// -----------------------------------------------------------------------------

export type CardContentRef = React.ElementRef<"div">;

export interface CardContentProps
  extends React.ComponentPropsWithoutRef<"div">,
    CardContentVariants {}

const CardContent = forwardRef<CardContentRef, CardContentProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        {...props}
        className={cardContentVariants({ className })}
        ref={ref}
      />
    );
  },
);

CardContent.displayName = "CardContent";

export { CardContent };

// -----------------------------------------------------------------------------

export type CardFooterRef = React.ElementRef<"div">;

export interface CardFooterProps
  extends React.ComponentPropsWithoutRef<"div">,
    CardFooterVariants {}

const CardFooter = forwardRef<CardFooterRef, CardFooterProps>(
  ({ className, palette, ...props }, ref) => {
    return (
      <div
        {...props}
        className={cardFooterVariants({ className, palette })}
        ref={ref}
      />
    );
  },
);

CardFooter.displayName = "CardFooter";

export { CardFooter };
