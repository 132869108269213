import { Container } from "#ui/container";
import { Copyright, Footer, FooterNav } from "#ui/footer";
import { Logo } from "#ui/logo";
import { CookieSettingsDialog } from "#app/_ui/components/dialogs/CookieSettingsDialog";
import NextLink from "next/link";

export function AuthorizedFooter() {
  return (
    <Footer className="py-100px text-shade-80">
      <Container className="space-y-30px">
        <NextLink href="/" className="w-fit">
          <Logo layout="mark-only" size="md" />
        </NextLink>
        <FooterNav>
          <a 
            href="https://www.rwbaird.com/help/privacy-notice/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>{" "}
          <a
            href="https://www.rwbaird.com/help/privacy-notice/#vertTab-5456"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookie Policy
          </a>{" "}
          <CookieSettingsDialog>Cookie Settings</CookieSettingsDialog>{" "}
          <NextLink href="/contact-us">Contact &amp; FAQs</NextLink>
        </FooterNav>
        <Copyright />
      </Container>
    </Footer>
  );
}
