const allowed = [
  "/documents",
  "/documents/statements",
  "/documents/received",
  "/sharing/accept",
  "/sharing/confirm",
  "/profile-and-settings/personal-information",
];

export function getRedirectUrl(searchParams: {
  [key: string]: string | string[] | undefined;
}) {
  let redirectUrl = "/";

  const redirectUrlParam = searchParams["redirectUrl"] as string;

  if (redirectUrlParam) {
    const entry = allowed.includes(redirectUrlParam);
    if (!entry) return redirectUrl;

    redirectUrl = redirectUrlParam;

    const paramValue = searchParams["token"] as string;
    if (paramValue) {
      redirectUrl = `${redirectUrl}?token=${encodeURIComponent(paramValue)}`;
    }
  }

  return redirectUrl;
}

export function buildRedirectUrl() {
  if (allowed.includes(location.pathname)) {
    return `/sign-in?redirectUrl=${location.pathname}&${location.search.slice(1)}`;
  }

  return "/sign-in";
}
